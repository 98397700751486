import React from "react";
import { Grid, Form, Message } from "semantic-ui-react";
import MailJetResource from "builder_portal/actions/mailJetActions";
import { connect } from "react-redux";
import { arrayOf, instanceOf, object } from "prop-types";

class AddAccount extends React.Component {
  state = {
    account_name: "",
    account_domain: "",
    project_name: "",
    mailjet_variation: "",
    with_demo_project: false,
    error: null,
    success: null,
    loading: false
  };

  componentDidMount() {
    const { mailJetResource } = this.props;
    mailJetResource.fetchAll();
  }

  handleSubmit = () => {
    const {
      account_name,
      account_domain,
      project_name,
      mailjet_variation,
      with_demo_project
    } = this.state;
    this.setState({
      account_name,
      account_domain,
      project_name,
      mailjet_variation,
      with_demo_project,
      loading: true
    });

    fetch("/api/v1/support_utils/add_account", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        account: {
          account_name,
          account_domain,
          project_name,
          mailjet_variation,
          with_demo_project
        }
      })
    })
      .then(response => response.json())
      .catch(error =>
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        })
      )
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      });
  };

  handleChange = (e, { name, value, checked }) => {
    this.setState({ [name]: checked !== undefined ? checked : value });
  };

  render() {
    const { mailJetOptions } = this.props;
    const {
      account_name,
      account_domain,
      project_name,
      mailjet_variation,
      with_demo_project,
      error,
      success,
      loading
    } = this.state;

    return (
      <div>
        <h2>Add Account</h2>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width="3">
            <Form
              loading={loading}
              success={success}
              error={!!error}
              onSubmit={this.handleSubmit}
            >
              <Message
                success
                header="Account Added!"
                content="Please check the results!"
              />
              <Message error header="Account Creation Failed" content={error} />
              <Form.Input
                name="account_name"
                value={account_name}
                onChange={this.handleChange}
                label="Account Name"
                placeholder="Bau Digital GmbH"
                required
                width="4"
              />
              <Form.Input
                name="account_domain"
                value={account_domain}
                onChange={this.handleChange}
                label="Account Domain"
                placeholder="baudigital.com"
                required
                width="4"
              />
              <Form.Input
                name="project_name"
                value={project_name}
                onChange={this.handleChange}
                label="Project Name"
                placeholder="Metropolapart"
                required
                width="4"
              />
              <Form.Select
                name="mailjet_variation"
                value={mailjet_variation}
                options={mailJetOptions}
                onChange={this.handleChange}
                label="Mail Account"
                placeholder="Choose one"
                required
                width="4"
              />
              <Form.Checkbox
                name="with_demo_project"
                checked={!!with_demo_project}
                onChange={this.handleChange}
                label="Create additional Demo Project?"
              />
              <Form.Button width="4">Submit</Form.Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}

AddAccount.propTypes = {
  mailJetOptions: arrayOf(object).isRequired,
  mailJetResource: instanceOf(MailJetResource).isRequired
};

const mapStateToProps = state => {
  const mailJetOptions = state.pageContent?.mailjet_variations?.map(
    variation => {
      return {
        key: variation,
        text: variation,
        value: variation
      };
    }
  );

  return {
    mailJetOptions
  };
};

const mapDispatchToProps = dispatch => ({
  mailJetResource: new MailJetResource(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
