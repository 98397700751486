import {
  shape,
  instanceOf,
  objectOf,
  arrayOf,
  object,
  string,
  array,
  number,
  bool,
  oneOfType
} from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown, Loader, Menu } from "semantic-ui-react";
import { includes, find } from "lodash";
import { getAccount, getProjects, getI18N } from "shared/selectors";
import { Account } from "shared/models/account";
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from "query-string";
import { locationShape } from "react-router";

import { withRouter } from "shared/helpers/withRouter";
import { browserHistory } from "shared/routes/browserHistory";
import Growl from "../../../actions/growlActions";
import { AccountResource } from "../../../actions/accountActions";
import { ProjectsResource } from "../../../actions/projectActions";
import ProjectTab from "./ProjectTab";
import AccountTab from "./AccountTab";
import silentHandleApiRequestErrors from "../../../../shared/helpers/silentHandleApiRequestErrors";
import removeDeprecatedLocalStorageItems from "../../../helpers/removeDeprecatedLocalStorageItems";
import "./buyerPortalSettings.scss";

removeDeprecatedLocalStorageItems(["recentBuyerPortalIds"]);

const BuyerPortal = ({ actions, growl, account, projects, i18n, location }) => {
  const queryParams = queryString.parse(location?.search);

  const projectId = Number(queryParams?.projectId);

  const storedIds =
    JSON.parse(localStorage.getItem("recentBuyerPortalConfigIds")) || {};
  const [project, setProject] = useState(null);
  const [recentProjectIds, setRecentProjectIds] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function writeIdsToLocalStorage(ids) {
    localStorage.setItem(
      "recentBuyerPortalConfigIds",
      JSON.stringify({
        ...storedIds,
        [account.data.account.id]: ids
      })
    );
  }

  useEffect(() => {
    actions.account.buyerPortal().catch(silentHandleApiRequestErrors);
  }, []);

  useEffect(() => {
    if (projects.length) {
      let initialIds = storedIds[account.data.account.id]?.filter(id =>
        find(projects, p => p.id === id)
      );

      if (storedIds[account.data.account.id] !== initialIds) {
        writeIdsToLocalStorage(initialIds);
      }
      if (!initialIds) {
        initialIds = projects.slice(0, 2).map(p => p.id);
        writeIdsToLocalStorage(initialIds);
      }
      setRecentProjectIds(initialIds);
    }
  }, [projects]);

  useEffect(() => {
    if (project && !includes(recentProjectIds, projectId)) {
      const nextIds = [
        projectId,
        recentProjectIds.find(p => p.id !== projectId)
      ];
      writeIdsToLocalStorage(nextIds);
      setRecentProjectIds(nextIds);
    }
  }, [project]);

  useEffect(() => {
    setDropdownOpen(false);
  }, [projectId, recentProjectIds]);

  useEffect(() => {
    const foundProject = find(projects, p => p.id === projectId);

    if (foundProject) {
      setProject(foundProject);
    } else {
      setProject(null);
    }
  }, [projectId, projects]);

  const handleUpload = (file, role) => {
    const token = JSON.parse(localStorage.getItem("token")) || "";
    const formData = new FormData();
    formData.append(`image[role]`, role);
    formData.append(`image[image]`, file);

    fetch(`/api/v1/projects/${project.id}/${role}`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      if (response.status === 201) {
        actions.account.buyerPortal();
      }
    });
  };

  function renderContent() {
    if (project) {
      return (
        <ProjectTab
          key={project.id}
          account={account}
          project={project}
          projects={projects}
          handleUpload={handleUpload}
          accountsResource={actions.account}
          projectsResource={actions.projects}
        />
      );
    }
    return <AccountTab accountsResource={actions.account} growl={growl} />;
  }

  if (!account?.data) return <Loader active />;

  const menuItems = recentProjectIds.map(id => {
    const item = projects.find(p => p.id === id);
    if (!item) return null;
    return (
      <Menu.Item
        key={item.id}
        name={item.name}
        onClick={() => {
          browserHistory.push(`/account/buyer_portal?projectId=${item.id}`);
        }}
        data-item={`project-tab-${item.id}`}
        active={projectId === item.id}
      >
        {item.name}
      </Menu.Item>
    );
  });

  const options = projects.map(item => {
    return {
      onClick: () => {
        browserHistory.push(`/account/buyer_portal?projectId=${item.id}`);
      },
      key: item.id,
      value: item.id,
      text: item.name,
      icon: "building"
    };
  });

  return (
    <>
      <Menu data-component="buyerPortalSettingsMenu">
        <Menu.Item
          key="products"
          name={i18n["product.title.catalog"]}
          onClick={() => {
            browserHistory.push(`/account/buyer_portal`);
          }}
          active={!project}
        >
          Allgemein
        </Menu.Item>
        {menuItems}
        <Menu.Menu position="right">
          <Dropdown
            item
            search
            scrolling
            onClick={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
            icon="search"
            open={dropdownOpen}
            text="Projekt auswählen"
            value={projectId}
            options={options}
          />
        </Menu.Menu>
      </Menu>
      {renderContent()}
    </>
  );
};

BuyerPortal.propTypes = {
  account: instanceOf(Account).isRequired,
  actions: shape({
    account: instanceOf(AccountResource).isRequired,
    projects: instanceOf(ProjectsResource).isRequired
  }).isRequired,
  growl: instanceOf(Growl).isRequired,
  projects: arrayOf(
    shape({
      id: oneOfType([string, number]),
      name: string,
      content: object
    })
  ).isRequired,
  i18n: objectOf(oneOfType([array, string, number, bool])).isRequired,
  location: locationShape.isRequired
};

const mapStateToProps = state => {
  return {
    i18n: getI18N(state),
    account: getAccount(state),
    projects: getProjects(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    growl: new Growl(dispatch),
    actions: {
      account: new AccountResource(dispatch),
      projects: new ProjectsResource(dispatch)
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BuyerPortal)
);
