import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { get, maxBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { Button, Form, Grid, Image, Tab, Modal } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import FloatInput from "shared/components/forms/FloatInput";
import { getUnitTypesOptions } from "builder_portal/selectors/unitTypes";
import { getSalesUserOptions } from "builder_portal/selectors/projectUsers";
import { getProjectFeatureOptions } from "builder_portal/selectors/projectFeatures";
import { getCities, getCityOptions } from "shared/selectors/cities";
import OptionsShape from "shared/shapes/options.shape";
import { I18nShape } from "shared/shapes/i18n.shape";
import { getAccount } from "shared/selectors";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import ImageSelector from "shared/components/forms/ImageSelector";
import { CitiesResource } from "builder_portal/actions/citiesActions";
import ProjectImagesLoader from "shared/components/forms/ProjectImagesLoader";
import JsonEditor from "shared/components/forms/JsonEditor";
import { AccountResource } from "../../actions/accountActions";
import ImageDropzone from "../dropzone/ImageDropzone";
import AccountManager from "./AccountManager";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";

import "./projectForm.scss";
import { If } from "../../../shared/components/elements/Conditions";
import "./project.scss";

const getSuburbOptions = (cities, cityId) => {
  return cities[cityId]?.suburbs.map(s => ({
    key: s.id,
    value: s.id,
    text: s.name
  }));
};

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "name",
      label: "project.attributes.name.label",
      message: "project.attributes.name.error",
      rule: "isRequired"
    },
    {
      id: "slug",
      label: "project.attributes.slug.label",
      message: "project.attributes.slug.error",
      rule: "isSlug"
    },
    {
      id: "status",
      label: "project.attributes.status.label",
      rule: "isRequired"
    },
    {
      id: "address",
      label: "project.attributes.address.label"
    },
    {
      id: "postcode",
      label: "project.attributes.postcode.label"
    },
    {
      id: "town",
      label: "project.attributes.town.label"
    },
    {
      id: "city_id",
      label: "project.attributes.town.label"
    },
    {
      id: "suburb_id",
      label: "project.attributes.suburb.label"
    },
    {
      id: "developer_name",
      label: "project.attributes.developerName.label"
    },
    {
      id: "developer_contact_person",
      label: "project.attributes.developerContactPerson.label"
    },
    {
      id: "developer_email",
      label: "project.attributes.developerEmail.label",
      message: "project.attributes.developerEmail.error",
      rule: "isEmailOrEmpty"
    },
    {
      id: "developer_phone",
      label: "project.attributes.developerPhone.label"
    },
    {
      id: "developer_address",
      label: "project.attributes.developerAddress.label"
    },
    {
      id: "developer_postcode",
      label: "project.attributes.developerPostcode.label"
    },
    {
      id: "developer_town",
      label: "project.attributes.developerTown.label"
    },
    {
      id: "account_manager_id",
      label: "project.attributes.accountManager.label"
    },
    {
      id: "customer_success_manager_id",
      label: "project.attributes.customerSuccessManager.label"
    },
    {
      id: "reporting_status",
      label: "project.attributes.reportingStatus.label"
    },
    {
      id: "default_unit_type_id",
      label: "project.attributes.defaultUnitType.label"
    },
    {
      id: "unit_quota",
      label: "project.attributes.unitQuota.label",
      rule: "isNumberOrZero"
    },
    {
      id: "license_key",
      label: "project.attributes.licenseKey.label"
    },
    {
      id: "custom_configurator_domain",
      label: "project.attributes.customConfiguratorDomain.label"
    },
    {
      id: "production",
      label: "project.attributes.production.label",
      control: "Checkbox"
    },
    {
      id: "external_id",
      label: "project.attributes.externalId.label"
    },
    {
      id: "marketing_website_url",
      label: "project.attributes.marketing_portal.marketing_website_url.label"
    },
    {
      id: "marketing_portal_activated",
      label:
        "project.attributes.marketing_portal.marketing_portal_activated.label",
      control: "Checkbox"
    },
    {
      id: "marketing_portal_configurator_link",
      label:
        "project.attributes.marketing_portal.marketing_portal_configurator_link.label",
      control: "Checkbox"
    },
    {
      id: "marketing_portal_summary",
      label:
        "project.attributes.marketing_portal.marketing_portal_summary.label"
    },
    {
      id: "marketing_portal_description",
      label:
        "project.attributes.marketing_portal.marketing_portal_description.label"
    },
    {
      id: "construction_begins_at",
      label: "project.attributes.marketing_portal.construction_begins_at.label",
      control: "DateInput"
    },
    {
      id: "construction_completes_at",
      label:
        "project.attributes.marketing_portal.construction_completes_at.label",
      control: "DateInput"
    },
    {
      id: "sales_user_id",
      label: "project.attributes.salesUser.label"
    },
    {
      id: "marketing_unit_price_min",
      label: "€",
      autoComplete: "off"
    },
    {
      id: "marketing_unit_price_max",
      label: "€",
      autoComplete: "off"
    },
    {
      id: "marketing_unit_floor_space_min",
      autoComplete: "off"
    },
    {
      id: "marketing_unit_floor_space_max",
      autoComplete: "off"
    },
    {
      id: "marketing_unit_rooms_min",
      autoComplete: "off"
    },
    {
      id: "marketing_unit_rooms_max",
      autoComplete: "off"
    },
    {
      id: "marketing_unit_count_total",
      label:
        "project.attributes.marketing_portal.marketing_unit_count_total.label",
      autoComplete: "off"
    },
    {
      id: "project_feature_ids",
      label: "project.attributes.projectFeature.label"
    },
    {
      id: "attachment_type_filters",
      label: "project.attributes.attachmentTypeFilters.label"
    },
    {
      id: "process_definition_ids",
      label: "project.attributes.processDefinitionIds.label",
      placeholder: "project.attributes.processDefinitionIds.placeholder"
    },
    {
      id: "marketing_gallery_image_ids",
      label: "project.attributes.marketing_gallery_image_ids.label"
    },
    {
      id: "thumb_id",
      label: "project.attributes.thumb_id.label"
    },
    {
      id: "agent_name",
      label: "project.attributes.external_sales.agent_name.label"
    },
    {
      id: "agent_name_full",
      label: "project.attributes.external_sales.agent_name_full.label"
    },
    {
      id: "agent_email",
      label: "project.attributes.external_sales.agent_email.label"
    },
    {
      id: "agent_phone",
      label: "project.attributes.external_sales.agent_phone.label"
    },
    {
      id: "agent_address_street",
      label: "project.attributes.external_sales.agent_address_street.label"
    },
    {
      id: "agent_address_postcode",
      label: "project.attributes.external_sales.agent_address_postcode.label"
    },
    {
      id: "agent_address_city",
      label: "project.attributes.external_sales.agent_address_city.label"
    },
    {
      id: "marketed_by_agent",
      label: "project.attributes.external_sales.marketed_by_agent.label",
      control: "Checkbox"
    },
    {
      id: "agent_logo_id",
      label: "project.attributes.external_sales.agent_logo_id.label"
    },
    {
      id: "custom_import_configs",
      label: "project.attributes.custom_import_configs.label"
    }
  ]
});
class ProjectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, model: props.model, addedCity: "" };
  }

  componentDidMount() {
    const { accountResource, citiesResource, account } = this.props;

    citiesResource.fetchAll();

    if (account.isSystemAdmin()) {
      accountResource.get(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { model } = this.props;
    if (model !== prevProps.model) {
      this.writeModelToState(model);
    }
  }

  writeModelToState = nextModel => {
    this.setState({ model: nextModel });
  };

  onSubmit = values => {
    const { props, state } = this;
    if (state.isLoading) {
      return false;
    }
    this.setState({ isLoading: true });
    /* When adding new city, city_id must have a value in order to show the newly created option
      But, ticket requirements is that after adding new city, city_id should be null. */
    // eslint-disable-next-line no-param-reassign
    if (state.addedCity === values.town) values.city_id = null;
    return props.save(values);
  };

  handleRemoveImage = () => {
    const { resource, model } = this.props;
    const id = model.id || undefined;
    resource.removeThumb(id).then(() => {
      resource.get(id);
    });
  };

  getReportingStatusOptions = () => {
    const { i18n } = this.props;
    return [
      {
        key: "onboarding",
        value: "onboarding",
        text: i18n["project.attributes.reportingStatus.onboarding"]
      },
      {
        key: "active",
        value: "active",
        text: i18n["project.attributes.reportingStatus.active"]
      },
      {
        key: "completed",
        value: "completed",
        text: i18n["project.attributes.reportingStatus.completed"]
      },
      {
        key: "abandoned",
        value: "abandoned",
        text: i18n["project.attributes.reportingStatus.abandoned"]
      }
    ];
  };

  handleMarketingImagesIds = value => {
    const { model } = this.state;
    this.setState({
      model: {
        ...model,
        marketing_gallery_image_ids: value
      }
    });
  };

  handleThumbUrl = value => {
    const { model } = this.state;
    this.setState({
      model: {
        ...model,
        thumb_id: value
      }
    });
  };

  handleAgentLogoUrl = value => {
    const { model } = this.state;
    this.setState({
      model: {
        ...model,
        agent_logo_id: value
      }
    });
  };

  handleAddition = (e, { value }) => {
    const { model, cityOptions } = this.props;
    const maxId = maxBy(cityOptions, "value")?.value;
    this.setState({
      addedCity: value,
      model: {
        ...model,
        town: value,
        city_id: maxId + 1,
        suburb_id: null
      }
    });
  };

  handleCityChange = (e, { value }) => {
    const { model } = this.props;
    if (!value) {
      this.setState({
        model: { ...model, town: "", city_id: null, suburb_id: null }
      });
    } else {
      const town = this.cityOptions().find(x => x.value === value)?.text;
      this.setState({
        model: { ...model, town, city_id: value, suburb_id: null }
      });
    }
  };

  cityOptions = () => {
    const { cityOptions } = this.props;
    const { addedCity } = this.state;
    // before saving new town to server, that option is added locally
    if (addedCity) {
      const maxId = maxBy(cityOptions, "value")?.value;
      return [...cityOptions].concat([
        {
          key: maxId + 1,
          value: maxId + 1,
          text: addedCity
        }
      ]);
    }
    return cityOptions;
  };

  renderDropzone() {
    const { model, i18n, resource } = this.props;

    const resourceId = model && Number(model.id);

    if (resourceId) {
      return (
        <ImageDropzone
          title={i18n["project.actions.uploadImage.label"]}
          className="projectDropZone"
          resourceName="projects"
          resourceId={resourceId}
          handleSuccess={() => {
            resource.get(resourceId); // refresh page
          }}
          i18n={i18n}
        />
      );
    }
    return null;
  }

  renderImage() {
    const { i18n, model } = this.props;
    return (
      <div className="imageContainer">
        <Image src={get(model, "thumb_url")} />
        <Button
          onClick={this.handleRemoveImage}
          color="red"
          size="tiny"
          data-tooltip={i18n["project.actions.removeImage.label"]}
          basic
          circular
          icon="remove"
          className="removeImage"
        />
      </div>
    );
  }

  render() {
    const {
      statusOptions,
      unitTypeOptions,
      salesUserOptions,
      projectFatureOptions,
      i18n,
      onClose,
      cities,
      account
    } = this.props;

    const { model } = this.state;
    const { state } = this;
    // const hasImage = Boolean(model && model.thumb_url);

    const form = FormFactory.create(model, i18n, {
      onChange: newModel => this.setState({ model: newModel })
    });

    form.fields.status.props.options = statusOptions;
    form.fields.license_key.props.readOnly = true;
    form.fields.reporting_status.props = {
      ...form.fields.reporting_status.props,
      options: this.getReportingStatusOptions()
    };
    form.fields.default_unit_type_id.props = {
      ...form.fields.default_unit_type_id.props,
      options: unitTypeOptions
    };
    form.fields.sales_user_id.props = {
      ...form.fields.sales_user_id.props,
      options: salesUserOptions,
      clearable: true
    };
    form.fields.account_manager_id.props = {
      ...form.fields.account_manager_id.props,
      options: salesUserOptions,
      clearable: true
    };
    form.fields.marketing_unit_price_min.props = {
      ...form.fields.marketing_unit_price_min.props,
      labelPosition: "right"
    };

    form.fields.marketing_unit_price_max.props = {
      ...form.fields.marketing_unit_price_max.props,
      labelPosition: "right"
    };

    form.fields.marketing_unit_floor_space_min.props = {
      ...form.fields.marketing_unit_floor_space_min.props,
      hideLabel: true
    };

    form.fields.marketing_unit_floor_space_max.props = {
      ...form.fields.marketing_unit_floor_space_max.props,
      hideLabel: true
    };
    form.fields.marketing_unit_rooms_min.props = {
      ...form.fields.marketing_unit_rooms_min.props,
      hideLabel: true
    };

    form.fields.marketing_unit_rooms_max.props = {
      ...form.fields.marketing_unit_rooms_max.props,
      hideLabel: true
    };

    form.fields.construction_begins_at.props = {
      ...form.fields.construction_begins_at.props,
      nullable: true,
      showClearDate: true
    };

    form.fields.construction_completes_at.props = {
      ...form.fields.construction_completes_at.props,
      nullable: true,
      showClearDate: true,
      fullWidth: true
    };

    form.fields.project_feature_ids.props = {
      ...form.fields.project_feature_ids.props,
      options: projectFatureOptions,
      multiple: true,
      clearable: true
    };

    form.fields.marketing_gallery_image_ids.props = {
      ...form.fields.marketing_gallery_image_ids.props,
      projectId: model.id,
      multiple: true,
      fieldName: "marketing_gallery_image_ids",
      onChange: this.handleMarketingImagesIds
    };

    form.fields.thumb_id.props = {
      ...form.fields.thumb_id.props,
      projectId: model.id,
      onChange: this.handleThumbUrl,
      multiple: false,
      title: i18n["project.attributes.thumb_id.label"]
    };

    form.fields.agent_logo_id.props = {
      ...form.fields.agent_logo_id.props,
      projectId: model.id,
      onChange: this.handleAgentLogoUrl,
      multiple: false,
      title: i18n["project.attributes.external_sales.agent_logo_id.label"]
    };

    form.fields.city_id.props = {
      ...form.fields.city_id.props,
      options: this.cityOptions(),
      allowAdditions: true,
      clearable: true,
      upward: true,
      search: true,
      selection: true,
      onAddItem: this.handleAddition,
      onChange: this.handleCityChange
    };

    form.fields.suburb_id.props = {
      ...form.fields.suburb_id.props,
      options: getSuburbOptions(cities, model.city_id),
      clearable: true,
      upward: true
    };

    form.fields.attachment_type_filters.props = {
      ...form.fields.attachment_type_filters.props,
      clearable: true,
      multiple: true,
      options: account.getDocumentTypes().map(type => ({
        key: type.id,
        value: type.id,
        text: type.label
      }))
    };

    form.fields.process_definition_ids.props = {
      ...form.fields.process_definition_ids.props,
      clearable: true,
      multiple: true,
      options: account.getProcessDefinitions().map(type => {
        return {
          key: type.config.id,
          value: type.config.id,
          text: type.config.name
        };
      })
    };

    const panes = [
      {
        menuItem: i18n["project.title.one"],
        render: () => (
          <Tab.Pane>
            <Form.Field>
              <Field component="Input" {...form.fields.name} />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.account_manager_id} />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.sales_user_id} />
            </Form.Field>
            <Form.Field className="margin top medium">
              <Field component="Select" {...form.fields.status} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.address} />
            </Form.Field>
            <If condition={cities[model.city_id]?.suburbs?.length > 0}>
              <Form.Group widths="2">
                <Form.Field width="16">
                  <Field component="Select" {...form.fields.suburb_id} />
                </Form.Field>
              </Form.Group>
            </If>
            <Form.Group widths="2">
              <Form.Field width="4">
                <Field component="Input" {...form.fields.postcode} />
              </Form.Field>

              <If condition={false}>
                <Form.Field width="12">
                  <Field component="Input" {...form.fields.town} />
                </Form.Field>
              </If>
              <Form.Field width="12">
                <Field component="Select" {...form.fields.city_id} />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Field component={ImageSelector} {...form.fields.thumb_id} />
            </Form.Field>
          </Tab.Pane>
        )
      },
      {
        menuItem: i18n["project.attributes.developerName.label"],
        render: () => (
          <Tab.Pane>
            <Form.Field>
              <Field component="Input" {...form.fields.developer_name} />
            </Form.Field>
            <Form.Field>
              <Field
                component="Input"
                {...form.fields.developer_contact_person}
              />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.developer_email} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.developer_phone} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.developer_address} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.developer_postcode} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.developer_town} />
            </Form.Field>

            <Form.Field>
              <Field
                component="Select"
                {...form.fields.process_definition_ids}
              />
            </Form.Field>

            <Form.Field>
              <Field
                component="Select"
                {...form.fields.attachment_type_filters}
              />
            </Form.Field>

            <FeatureToggleActive featureToggleName="show_external_id">
              <Form.Field>
                <Field component="Input" {...form.fields.external_id} />
              </Form.Field>
            </FeatureToggleActive>
          </Tab.Pane>
        )
      },
      {
        menuItem: i18n["project.attributes.marketing_portal.label"],
        render: () => (
          <Tab.Pane>
            <Form.Field>
              <Field component="Input" {...form.fields.marketing_website_url} />
            </Form.Field>
            <Form.Group widths="2">
              <Form.Field>
                <Field
                  component="Checkbox"
                  {...form.fields.marketing_portal_activated}
                />
              </Form.Field>
              <Form.Field>
                <Field
                  component="Checkbox"
                  {...form.fields.marketing_portal_configurator_link}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Field
                component="TextArea"
                {...form.fields.marketing_portal_summary}
              />
            </Form.Field>
            <Form.Field>
              <Field
                component="TextArea"
                {...form.fields.marketing_portal_description}
              />
            </Form.Field>
            <Form.Group widths="2">
              <Form.Field width={8}>
                <Field
                  component="DateInput"
                  {...form.fields.construction_begins_at}
                />
              </Form.Field>
              <Form.Field width={8}>
                <Field
                  component="DateInput"
                  {...form.fields.construction_completes_at}
                  nullable
                  showClearDate
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
              <Form.Field width={8}>
                <label htmlFor="marketing_unit_price_min">
                  <FormattedMessage id="project.attributes.marketing_portal.marketing_unit_price_min.label" />
                </label>
                <Field
                  component={FloatInput}
                  {...form.fields.marketing_unit_price_min}
                />
              </Form.Field>
              <Form.Field width={8}>
                <label htmlFor="marketing_unit_price_max">
                  <FormattedMessage id="project.attributes.marketing_portal.marketing_unit_price_max.label" />
                </label>
                <Field
                  component={FloatInput}
                  {...form.fields.marketing_unit_price_max}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
              <Form.Field width={8}>
                <label htmlFor="marketing_unit_floor_space_min">
                  <FormattedMessage id="project.attributes.marketing_portal.marketing_unit_floor_space_min.label" />
                </label>
                <Field
                  component={FloatInput}
                  {...form.fields.marketing_unit_floor_space_min}
                />
              </Form.Field>
              <Form.Field width={8}>
                <label htmlFor="marketing_unit_floor_space_max">
                  <FormattedMessage id="project.attributes.marketing_portal.marketing_unit_floor_space_max.label" />
                </label>
                <Field
                  component={FloatInput}
                  {...form.fields.marketing_unit_floor_space_max}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
              <Form.Field width={8}>
                <label htmlFor="marketing_unit_rooms_min">
                  <FormattedMessage id="project.attributes.marketing_portal.marketing_unit_rooms_min.label" />
                </label>
                <Field
                  component={FloatInput}
                  {...form.fields.marketing_unit_rooms_min}
                />
              </Form.Field>
              <Form.Field width={8}>
                <label htmlFor="marketing_unit_rooms_max">
                  <FormattedMessage id="project.attributes.marketing_portal.marketing_unit_rooms_max.label" />
                </label>
                <Field
                  component={FloatInput}
                  {...form.fields.marketing_unit_rooms_max}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Field
                component="Input"
                {...form.fields.marketing_unit_count_total}
              />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.project_feature_ids} />
            </Form.Field>
            <Form.Field>
              <Field
                component={ImageSelector}
                {...form.fields.marketing_gallery_image_ids}
              />
            </Form.Field>
          </Tab.Pane>
        )
      },
      {
        menuItem: i18n.bau_digital,
        render: () => (
          <Tab.Pane>
            <AccountManager form={form} model={model} />
            <IsSystemAdmin styles={{ display: "block", marginBottom: "10px" }}>
              <If condition={model.reporting_status === "onboarding"}>
                <Form.Field>
                  <Field component="Input" {...form.fields.slug} />
                </Form.Field>
              </If>
              <Form.Field>
                <Field component="Select" {...form.fields.reporting_status} />
              </Form.Field>
              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.default_unit_type_id}
                />
              </Form.Field>
              <Form.Field>
                <Field component="Input" {...form.fields.unit_quota} />
              </Form.Field>
              <Form.Field>
                <Field component="Input" {...form.fields.license_key} />
              </Form.Field>
              <Form.Field>
                <Field
                  component="Input"
                  {...form.fields.custom_configurator_domain}
                />
              </Form.Field>
              <Form.Field>
                <Field component="Checkbox" {...form.fields.production} />
              </Form.Field>
              <Form.Field>
                <Field
                  component={JsonEditor}
                  {...form.fields.custom_import_configs}
                />
              </Form.Field>
            </IsSystemAdmin>
          </Tab.Pane>
        )
      },
      {
        menuItem: i18n["project.attributes.external_sales.label"],
        render: () => (
          <Tab.Pane>
            <Form.Field>
              <Field component="Input" {...form.fields.agent_name} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.agent_name_full} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.agent_email} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.agent_phone} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.agent_address_street} />
            </Form.Field>
            <Form.Field>
              <Field
                component="Input"
                {...form.fields.agent_address_postcode}
              />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.agent_address_city} />
            </Form.Field>
            <Form.Field>
              <Field component="Checkbox" {...form.fields.marketed_by_agent} />
            </Form.Field>
            <Form.Field>
              <Field component={ImageSelector} {...form.fields.agent_logo_id} />
            </Form.Field>
          </Tab.Pane>
        )
      }
    ];

    return (
      <>
        <Modal.Content scrolling>
          <ProjectImagesLoader projectId={model.id}>
            <span />
          </ProjectImagesLoader>
          <Form id="editProject" data-component="projectForm">
            <Grid data-component="projectForm">
              <Grid.Column width="16">
                <Tab panes={panes} />
              </Grid.Column>
              {/* <Grid.Column width="16">
                {hasImage ? this.renderImage() : this.renderDropzone()}
              </Grid.Column> */}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column textAlign="right" width={8} floated="right">
              <Button
                basic
                id="cancel"
                content={i18n["meta.actions.cancel"]}
                onClick={() => onClose()}
              />
              <Button
                primary
                type="submit"
                loading={state.isLoading}
                onClick={form.handleSubmit(this.onSubmit)}
                id="editProject"
                data-form="editProject"
              >
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </>
    );
  }
}

ProjectForm.propTypes = {
  i18n: I18nShape.isRequired,
  /* eslint-disable react/forbid-prop-types */
  model: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  statusOptions: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  unitTypeOptions: PropTypes.arrayOf(OptionsShape).isRequired,
  salesUserOptions: PropTypes.arrayOf(OptionsShape).isRequired,
  projectFatureOptions: PropTypes.arrayOf(OptionsShape).isRequired,
  onClose: PropTypes.func.isRequired,
  account: PropTypes.object,
  accountResource: PropTypes.instanceOf(AccountResource),
  citiesResource: PropTypes.instanceOf(CitiesResource).isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  cityOptions: PropTypes.arrayOf(OptionsShape)
};

ProjectForm.defaultProps = {
  account: null,
  accountResource: null,
  cities: [],
  cityOptions: []
};

const mapStateToProps = state => {
  return {
    unitTypeOptions: getUnitTypesOptions(state),
    salesUserOptions: getSalesUserOptions(state),
    projectFatureOptions: getProjectFeatureOptions(state),
    account: getAccount(state),
    cities: getCities(state),
    cityOptions: getCityOptions(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    accountResource: new AccountResource(dispatch),
    citiesResource: new CitiesResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
