import { get, omitBy } from "lodash";
import {
  instanceOf,
  oneOfType,
  number,
  string,
  func,
  shape,
  object,
  arrayOf
} from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Divider, Grid, Header, Loader } from "semantic-ui-react";
import { getAccount, getProcessDefinitions } from "shared/selectors";
import { ActivityShape } from "shared/shapes/activity.shape";
import { UPDATE_FILTER } from "shared/constants/appConstants";
import { If } from "shared/components/elements/Conditions";
import { Account } from "shared/models/account";
import { I18nShape } from "shared/shapes/i18n.shape";
import {
  ActivityFilterResource,
  ProjectActivitiesResource,
  StatisticsResource
} from "../../../actions/projectActions";
import ActivityListItem from "../../activity/ActivityListItem";
import Pager from "../../pager/Pager";
import ActionBar from "./ActionBar";
import PhaseStatistics from "./PhaseStatistics";
import UniversalFilterBox from "./UniversalFilterBox";
import "./activity.scss";

const activityContacts = arrayOf(
  shape({ id: oneOfType([string, number]), label: string })
);

const ActivityList = props => {
  const {
    id,
    account,
    activities,
    i18n,
    setFilter,
    filter,
    assignees,
    contractors,
    units,
    sections,
    buyers,
    trades,
    statistics,
    meta,
    reportUrls,
    processDefinitions,
    activitiesResource,
    statisticsResource,
    activityFilterResource
  } = props;

  function exceptPhase(phaseFilter) {
    return omitBy(phaseFilter, (v, f) => {
      return f === "progress";
    });
  }

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    activitiesResource.fetchAll(filter);
    statisticsResource.fetchAll(exceptPhase(filter));
    activityFilterResource.fetchAll();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [activities, statistics]);

  useEffect(() => {
    setLoading(true);
    activitiesResource.fetchAll(filter);
    statisticsResource.fetchAll(exceptPhase(filter));
  }, [filter]);

  function handlePagerClick(page) {
    props.setFilter({ ...filter, page: parseInt(page, 10) });
  }

  function handleStatisticClick(progress) {
    if (progress) {
      setFilter({ ...filter, page: 1, progress });
    } else {
      const newFilter = { ...props.filter, page: 1 };
      delete newFilter.progress;
      setFilter(newFilter);
    }
  }

  function renderChildren() {
    if (loading) return <Loader active inline="centered" />;
    const children =
      (activities &&
        activities.map(item => {
          return (
            <ActivityListItem
              projectId={id}
              account={account}
              listType={filter.sortBy || "deadline_due_at"}
              item={item}
              key={`item-${item.id}`}
            />
          );
        })) ||
      [];

    if (children.length > 0) {
      return children;
    }
    return (
      <FormattedMessage
        id="activity.message.noResults"
        defaultMessage="activity.message.noResults"
      />
    );
  }

  function getUnitOptions() {
    return units.map(unit => {
      return {
        id: unit.id,
        label: `${unit.label} - ${get(unit, "buyer", "k.A.")}`
      };
    });
  }

  return (
    <div data-component="activities">
      <If condition={account.isEnabled("show_page_layout_v2")}>
        <Header size="large">
          <FormattedMessage
            id="project.tabs.activity"
            defaultMessage="Vorgänge"
          />
        </Header>
      </If>
      <Grid
        stackable
        data-component="activityListFilter"
        className="margin bottom medium"
      >
        <Grid.Column width={16}>
          <UniversalFilterBox
            i18n={i18n}
            setFilter={setFilter}
            filter={filter}
            currentUser={account.getCurrentUser()}
            assignees={assignees}
            contractors={contractors}
            units={units}
            buyers={buyers}
            sections={sections}
            trades={trades}
            processDefinitions={processDefinitions}
          />
        </Grid.Column>
      </Grid>

      <PhaseStatistics
        statistics={statistics}
        filter={filter.progress}
        onSetFilter={handleStatisticClick}
      />
      <Divider hidden />

      <ActionBar
        i18n={i18n}
        projectId={id}
        reportUrls={reportUrls}
        processDefinitions={processDefinitions}
        activitiesResource={activitiesResource}
        units={getUnitOptions()}
        sections={sections}
      />
      <Divider hidden />
      <div data-component="activityListContainer">{renderChildren()}</div>

      {meta && (
        <Pager
          currentPage={meta.page}
          itemsPerPage={meta.limit}
          totalItems={meta.total}
          onClick={handlePagerClick}
        />
      )}
    </div>
  );
};

ActivityList.propTypes = {
  id: oneOfType([number, string]).isRequired,
  account: instanceOf(Account).isRequired,
  i18n: I18nShape.isRequired,
  setFilter: func.isRequired,
  filter: shape({ page: number, progress: string }),
  meta: shape({ limit: number, page: number, total: number }),
  // eslint-disable-next-line camelcase
  reportUrls: shape({ xlsx_by_contractor: string, xlsx_by_unit: string }),
  statistics: shape({
    close: object,
    completed: object,
    ontime: object,
    overdue: object
  }),
  activities: arrayOf(ActivityShape),
  assignees: activityContacts.isRequired,
  contractors: activityContacts.isRequired,
  units: activityContacts.isRequired,
  sections: activityContacts.isRequired,
  buyers: activityContacts.isRequired,
  trades: activityContacts.isRequired,
  processDefinitions: arrayOf(object).isRequired,
  activitiesResource: instanceOf(ProjectActivitiesResource).isRequired,
  activityFilterResource: instanceOf(ActivityFilterResource).isRequired,
  statisticsResource: instanceOf(StatisticsResource).isRequired
};

ActivityList.defaultProps = {
  statistics: {},
  reportUrls: null,
  meta: null,
  filter: {}
};

const mapDispatchToProps = (dispatch, props) => ({
  setFilter: filterSettings => {
    dispatch({
      type: UPDATE_FILTER,
      payload: {
        project: {
          [props.id]: {
            activities: filterSettings
          }
        }
      }
    });
  },
  activitiesResource: new ProjectActivitiesResource(dispatch, props.id),
  activityFilterResource: new ActivityFilterResource(dispatch, props.id),
  statisticsResource: new StatisticsResource(dispatch, props.id)
});

const mapStateToProps = (state, props) => {
  const { i18n, pageContent, filter } = state;
  const { id } = props;

  const activitiesFilter = get(filter, `project.${id}.activities`, undefined);

  return {
    account: getAccount(state),
    activities: pageContent && pageContent.activities,
    assignees: get(pageContent, "activitiesFilter.assignees", []),
    buyers: get(pageContent, "activitiesFilter.buyers", []),
    contractors: get(pageContent, "activitiesFilter.contractors", []),
    filter: activitiesFilter,
    i18n,
    meta: pageContent && pageContent.meta,
    reportUrls: pageContent && pageContent.report_urls,
    sections: get(pageContent, "activitiesFilter.sections", []),
    statistics: pageContent && pageContent.statistics,
    trades: get(pageContent, "activitiesFilter.trades", []),
    units: get(pageContent, "activitiesFilter.units", []),
    processDefinitions: getProcessDefinitions(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);
