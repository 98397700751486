/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  get,
  maxBy,
  sortBy,
  merge,
  compact,
  keyBy,
  values,
  uniqBy,
  differenceWith,
  isEqual
} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import InputWithActions from "shared/components/forms/InputWithActions";
import { FormDefinition, rules } from "shared/components/forms/FormDefinition";
import htmlToText from "html-to-text";
import { If } from "shared/components/elements/Conditions";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { BuyerShape } from "shared/shapes/buyer.shape";
import { MessageShape } from "shared/shapes/message.shape";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Growl from "builder_portal/actions/growlActions";
import {
  MessageDraftsResource,
  MessageDraftsForActivitiesResource
} from "builder_portal/actions/messageDraftsActions";
import CreateContactDialog from "builder_portal/components/messages/contacts/CreateContactDialog";
import { byProperty, byUniq } from "../../../shared/helpers/filters";
import { Account } from "../../../shared/models/account";
import ActivityController from "../../containers/activity/activityController";
import getContactOptions from "../../helpers/getContactOptions";
import { withLoadingIndicator } from "../helpers/withLoadingIndicator";
import AttachmentsDialog from "./AttachmentsDialog";
import GeneratePreviewButton from "./GeneratePreviewButton";
import GenerateMultiplePreviewButton from "./GenerateMultiplePreviewButton";
import "./messageForm.scss";
import WysiwygEditor from "../../../shared/components/elements/wysiwyg/WysiwygEditor";
import { ActivityShape } from "../../../shared/shapes/activity.shape";
import { MacroShape } from "../../../shared/shapes/macro.shape";
import { I18nShape } from "../../../shared/shapes/i18n.shape";
import { ContractorShape } from "../../../shared/shapes/contractor.shape";

const INIT_MESSAGE = {
  macro_tag: "email",
  subject: "",
  body_html: "",
  recipients: [],
  cc: [],
  bcc: [],
  attachments: [],
  render_message_thread: true
};

class MessageForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const message = this.initialMessageData();

    this.state = {
      message: message || INIT_MESSAGE,
      isLoading: false,
      ccOptions: this.getAvailableContacts(),
      bccOptions: this.getAvailableContacts(),
      recipientsOptions: this.getAvailableContactOptions(),
      cc: !!message?.cc?.length,
      bcc: !!message?.bcc?.length,
      touched: !!message.isFromMacro,
      showContactModal: false,
      contactModalType: null
    };
    this.withLoadingIndicator = withLoadingIndicator.bind(this);
  }

  handleChange(data) {
    this.setState({ message: data, touched: true });
  }

  getMessageFromMacro() {
    const {
      activity,
      buyer,
      buyers,
      contractors,
      macro,
      contacts,
      defaultAttachments = [],
      i18n
    } = this.props;

    const ctx = {
      activity_id: activity.display_name,
      activity_name: activity.title,
      activity_summary: activity.summary,
      project_name: activity.project.label,
      project_developer_name: activity.project.developer_name,
      unit_id: get(activity, "units[0].label", "k.A."),
      buyer: buyer ? buyer.label : "k.A.",
      buyer_address: buyer
        ? [
            [buyer.address_street, buyer.address_number].join(" "),
            buyer.address_postcode,
            buyer.address_town
          ].join(", ")
        : "k.A.",
      buyer_contact: buyer ? [buyer.phone, buyer.email].join(", ") : "k.A.",
      deadline: moment(macro.due_at).format("DD.MM.YYYY")
    };

    // "auto_select": ["Buyer"]
    // "auto_select_by_key": { "email": "foo@example.com" }
    const contactSelectByType = get(macro, "config.contacts.auto_select");
    const contactSelectByKey = get(macro, "config.contacts.auto_select_by_key");

    const recipientsSelectByType =
      get(macro, "config.recipients.auto_select") || contactSelectByType;
    const recipientsSelectByKey =
      get(macro, "config.recipients.auto_select_by_key") || contactSelectByKey;

    const selectContacts = (selectorByType, selectorByKey) => {
      return Object.keys(contacts).reduce((accu, key) => {
        if (selectorByType || selectorByKey) {
          const contact = contacts[key];
          const filterByType = selectorByType?.indexOf(contact.type) >= 0;
          const filterByKey =
            selectorByKey &&
            contact[Object.keys(selectorByKey)[0]] ===
              selectorByKey[Object.keys(selectorByKey)[0]];
          if (contact.email && (filterByType || filterByKey)) {
            accu.push(key);
          }
        }
        return accu;
      }, []);
    };

    const messageContacts = selectContacts(
      contactSelectByType,
      contactSelectByKey
    );
    const messageRecipients = selectContacts(
      recipientsSelectByType,
      recipientsSelectByKey
    );

    const recipientMap = {
      Buyer: keyBy(buyers, "id"),
      Contractor: keyBy(contractors, "id")
    };

    const filteredRowData = messageContacts
      .map(id => contacts[id])
      .map(contact => get(recipientMap, [contact.type, contact.id]))
      .filter(c => !!c);

    const mappedMultipleReceiverSalutationsContent = filteredRowData
      .map(recipient => {
        const greeting = i18n[
          `meta.form_of_address.greeting.${recipient?.salutation || "default"}`
        ].toLowerCase();

        return compact([
          greeting,
          i18n[
            `meta.form_of_address.salutation.${recipient?.salutation ||
              "empty"}`
          ],
          i18n[`meta.form_of_address.title.${recipient?.title || "empty"}`],
          recipient?.last_name,
          recipient?.contact_person
        ]).join(" ");
      })
      .join(", <br>");

    const multipleReceiverSalutationsContent =
      mappedMultipleReceiverSalutationsContent.charAt(0).toUpperCase() +
      mappedMultipleReceiverSalutationsContent.slice(1);

    const firstRowData = filteredRowData.map(recipient => {
      return {
        greeting:
          i18n[
            `meta.form_of_address.greeting.${recipient.salutation || "default"}`
          ],
        salutation:
          i18n[
            `meta.form_of_address.salutation.${recipient.salutation || "empty"}`
          ],
        title: i18n[`meta.form_of_address.title.${recipient.title || "empty"}`],
        full_name: compact([
          recipient.last_name,
          recipient.contact_person
        ]).join(" "),
        multiple_receiver_salutations: multipleReceiverSalutationsContent || ""
      };
    })[0] || {
      greeting: "",
      salutation: "",
      title: "",
      full_name: i18n["meta.form_of_address.greeting.defaultGeneric"],
      multiple_receiver_salutations: ""
    };

    merge(ctx, firstRowData);

    const currentMessage = {
      subject: this.renderTemplate(macro.subject_template, ctx),
      body_html: this.renderTemplate(macro.body_template, ctx),
      recipients: messageRecipients,
      attachments: defaultAttachments,
      macro_tag: macro.macro_id,
      activity_id: activity.id,
      cc: [],
      bcc: [],
      isFromMacro: true
    };

    const recipients = get(macro, "recipients");
    if (recipients) {
      currentMessage.recipients = macro.recipients;
    }

    delete currentMessage.config;

    return currentMessage;
  }

  getOptionContent = (icon, text, email, address_town) => {
    return (
      <Header as="h5">
        <Icon name={icon} />
        <Header.Content>
          {text}
          <Header.Subheader>
            {email && compact([address_town, email]).join(", ")}
            {!email && (
              <FormattedMessage id="messaging.recipient_options.no_email" />
            )}
          </Header.Subheader>
        </Header.Content>
      </Header>
    );
  };

  getAvailableContacts() {
    const { contacts } = this.props;
    const list = values(contacts)?.map(contact => {
      const icons = {
        Buyer: "child",
        Contractor: "truck",
        User: "user",
        Contact: "address card outline"
      };

      const { type, id, text, email } = contact;
      // "[Achtung: Keine E-Mail hinterlegt]"
      return {
        key: `${type}-${id}`,
        value: `${type}-${id}`,
        text,
        disabled: !email,
        content: this.getOptionContent(icons[type], text, email, "")
      };
    });
    return uniqBy(list, "key");
  }

  getTitle() {
    const { i18n, macro } = this.props;
    if (macro && macro.call_to_action) {
      return macro.call_to_action;
    }
    if (macro && macro.parent_id) {
      return i18n["meta.actions.message.replyEmail"];
    }
    return i18n["meta.actions.message.newEmail"];
  }

  getRecipients = data => {
    const { contacts } = this.props;

    const recipients =
      data.recipients?.map(recipientId => {
        return { ...contacts[recipientId], mode: "to" };
      }) || [];

    if (data.cc)
      data.cc.forEach(cc => {
        if (rules.isEmail(cc)) {
          recipients.push({ ...contacts[cc], mode: "cc" });
        } else {
          recipients.push({
            label: cc,
            email: cc,
            mode: "cc"
          });
        }
      });

    if (data.bcc)
      data.bcc.forEach(bcc => {
        if (rules.isEmail(bcc)) {
          recipients.push({ ...contacts[bcc], mode: "bcc" });
        } else {
          recipients.push({
            label: bcc,
            email: bcc,
            mode: "bcc"
          });
        }
      });

    return recipients;
  };

  handleUpdate = data => {
    const { ctxCtrl, handleClose, handleUpdate } = this.props;
    const render_mode = data.render_message_thread ? "thread" : "message";
    const recipients = this.getRecipients(data);

    const bodyHtml = get(data, "body_html", "");
    const bodyText = htmlToText.fromString(bodyHtml, {
      singleNewLineParagraphs: true
    });

    return this.withLoadingIndicator(ctxCtrl.sendMessage)({
      ...data,
      body_text: bodyText,
      recipients,
      render_mode,
      id: undefined
    })
      .then(() => this.tryDeleteDraft().then(handleUpdate))
      .then(handleClose);
  };

  handleCancellation = closeConfirmation => {
    this.setState({
      message: this.initialMessageData() || INIT_MESSAGE
    });

    this.tryDeleteDraft();
    closeConfirmation();
  };

  getOptionContent = (icon, label, email) => {
    return (
      <Header as="h5">
        <Icon name={icon} />
        <Header.Content>
          {label}
          <Header.Subheader>
            {email}
            {!email && (
              <FormattedMessage id="messaging.recipient_options.no_email" />
            )}
          </Header.Subheader>
        </Header.Content>
      </Header>
    );
  };

  getAvailableContactOptions() {
    const { macro, contacts } = this.props;

    const filter = get(macro, "config.contacts.filter");

    if (filter) {
      const filteredContacts = Object.keys(contacts).reduce((accu, key) => {
        if (filter.indexOf(contacts[key].type) >= 0) {
          accu[key] = contacts[key];
        }
        return accu;
      }, {});

      return getContactOptions(filteredContacts, true, this.getOptionContent);
    }
    return getContactOptions(contacts, true, this.getOptionContent);
  }

  getAvailableAttachments() {
    const { account, attachments } = this.props;
    const byUniqId = byUniq("id");

    return account.getDocumentTypes().reduce((accu, documentType) => {
      accu[documentType.id] = Object.keys(attachments).reduce(
        (accu2, referenceGroup) => {
          const filteredAttachments = attachments[referenceGroup]
            .filter(byProperty("role", documentType.id))
            .filter(byUniqId);
          accu2[referenceGroup] = sortBy(
            filteredAttachments,
            "created_at"
          ).reverse();
          return accu2;
        },
        {}
      );
      return accu;
    }, {});
  }

  getDefaultAttachmentCategory() {
    const { attachments } = this.props;
    const activyAttachments = attachments.activity;
    const newestAttachment = maxBy(activyAttachments, "created_at");
    return newestAttachment && newestAttachment.role;
  }

  createMessageBody = () => {
    const { macro, emailReplyIncludeThread } = this.props;
    if (emailReplyIncludeThread && macro?.parent_id)
      return `<p /><p /><p />  ${macro?.body}`;
    return "";
  };

  tryDeleteDraft = () => {
    const {
      messageDraft,
      deleteDraft,
      loadActivityDrafts,
      handleClose,
      macro
    } = this.props;

    const { savedMessageDraftId } = this.state;

    const messageDraftId =
      messageDraft?.id || savedMessageDraftId || (macro.isDraft && macro.id); // draft reply is sent as macro

    if (messageDraftId) {
      this.setState(prev => ({ ...prev, isLoading: true }));
      return deleteDraft(messageDraftId)
        .then(() => {
          this.setState(prev => ({
            ...prev,
            message: INIT_MESSAGE,
            savedMessageDraftId: undefined,
            isLoading: false
          }));
          handleClose();
          return loadActivityDrafts();
        })
        .catch(() => this.setState(prev => ({ ...prev, isLoading: false })));
    }
    return Promise.resolve();
  };

  onSaveDraft = e => {
    e.preventDefault();
    const {
      saveDraft,
      activity,
      loadActivityDrafts,
      macro,
      growl
    } = this.props;
    const { message } = this.state;

    const recipients = this.getRecipients(message);

    const temp = {
      ...message,
      body_html: message.body_html,
      recipients,
      project_id: activity.project_id,
      id: message?.id,
      activity_id: activity.id,
      call_to_action: message.call_to_action || macro.call_to_action
    };

    const { _errors, _touched, cc, bcc, ...draft } = temp;

    this.setState(prev => ({ ...prev, isLoading: true }));
    saveDraft(draft)
      .then(res => {
        this.setState({
          touched: false,
          message: { ...message, id: res.data?.message_draft?.id },
          savedMessageDraftId: res.data?.message_draft?.id,
          isLoading: false
        });
        growl.success(
          "messaging.drafts.messages.saved.title",
          "messaging.drafts.messages.saved.body"
        );
        loadActivityDrafts();
      })
      .catch(() => this.setState(prev => ({ ...prev, isLoading: false })));
  };

  convertRecipientFormat = recipient => {
    // user:1 to User-1
    const parts = recipient.split(":");
    if (parts.length !== 2) return recipient;
    return `${parts[0].charAt(0).toUpperCase()}${parts[0].slice(1)}-${
      parts[1]
    }`;
  };

  cancel = event => {
    event.preventDefault();
    this.props.handleClose();
  };

  onDialogClose = () => {
    this.setState({
      showContactModal: false
    });
  };

  openContactDialogButton = type => (
    <div
      onClick={e => {
        e.preventDefault();
        this.setState(prevState => ({
          ...prevState,
          showContactModal: true,
          contactModalType: type
        }));
      }}
      style={{
        width: "100%",
        cursor: "pointer"
      }}
    >
      <Icon
        name="add"
        style={{
          marginTop: "2px"
        }}
      />
      <a href="#">
        <FormattedMessage id="messaging.message_container.empty_references.add_new_contact" />
      </a>
    </div>
  );

  buildForm() {
    const { contacts, account, activity, ctxCtrl, i18n } = this.props;

    const { ccOptions, bccOptions, message, recipientsOptions } = this.state;

    const fields = [
      {
        id: "body",
        label: "email.attributes.body.label",
        placeholder: "email.attributes.body.placeholder"
      },
      {
        id: "parent_id"
      },
      {
        id: "subject",
        label: "email.attributes.subject.label",
        rule: "isRequired",
        placeholder: "email.attributes.subject.placeholder",
        message: "email.attributes.subject.error"
      },
      {
        id: "recipients",
        label: "email.attributes.recipients.label",
        rule: "isRequired",
        placeholder: "email.attributes.recipients.placeholder",
        message: "email.attributes.recipients.error"
      },
      {
        id: "cc",
        label: "email.attributes.cc.label",
        placeholder: "email.attributes.recipients.placeholder"
      },
      {
        id: "bcc",
        label: "email.attributes.bcc.label",
        placeholder: "email.attributes.recipients.placeholder"
      },
      {
        id: "attachments",
        label: "email.attributes.attachments.label",
        placeholder: "email.attributes.attachments.placeholder"
      },
      {
        id: "render_message_thread",
        label: "email.attributes.render_message_thread.label",
        control: "Checkbox"
      }
    ];

    const selectableRecipients = message.recipients.filter(
      recipient => !!contacts[recipient]
    );

    const FormFactory = new FormDefinition({ fields });
    const form = FormFactory.create(
      { ...message, recipients: selectableRecipients },
      this.props.i18n,
      {
        onChange: data => this.handleChange(data)
      }
    );

    form.fields.recipients.props = {
      ...form.fields.recipients.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      selection: true,
      noResultsMessage: (
        <div>
          <FeatureToggle featureToggleName="email_contacts">
            {this.openContactDialogButton("recipients")}
          </FeatureToggle>
          <FeatureToggle featureToggleName="email_contacts" disabled>
            <FormattedMessage id="messaging.message_container.empty_references.no_results_found" />
          </FeatureToggle>
        </div>
      ),
      options: recipientsOptions
    };

    form.fields.cc.props = {
      ...form.fields.cc.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      selection: true,
      options: ccOptions,
      allowAdditions: true,
      additionLabel: `${i18n["email.attributes.add_contact.label"]} `,
      onAddItem: (_, { options, value }) => {
        options.push({
          key: value,
          value,
          text: value,
          email: value,
          disabled: false
        });
        this.setState(prev => ({ ...prev, ccOptions: options }));
      }
    };

    form.fields.bcc.props = {
      ...form.fields.bcc.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      selection: true,
      options: bccOptions,
      allowAdditions: true,
      onAddItem: (_, { options, value }) => {
        options.push({
          key: value,
          value,
          text: value,
          email: value,
          disabled: false
        });
        this.setState(prev => ({ ...prev, bccOptions: options }));
      }
    };

    form.fields.attachments.props = {
      ...form.fields.attachments.props,
      resourceId: activity.id,
      resourceName: "/activities",
      options: this.getAvailableAttachments(),
      defaultCategory: this.getDefaultAttachmentCategory(),
      categories: account.getDocumentTypes(),
      ctxCtrl
    };

    form.fields.body.props.rows = 10;

    return form;
  }

  initialMessageData() {
    const { macro, messageDraft } = this.props;

    if (Object.keys(messageDraft).length) {
      const recipients = messageDraft.recipients?.map(r =>
        this.convertRecipientFormat(r)
      );
      const cc = messageDraft.cc?.map(r => this.convertRecipientFormat(r));
      const bcc = messageDraft.bcc?.map(r => this.convertRecipientFormat(r));

      return {
        ...messageDraft,
        recipients,
        cc,
        bcc,
        attachments: messageDraft.attachments?.map(a => a.id) || [],
        message: {
          recipients: ["Contact-89"]
        }
      };
    }
    if (!macro) return null;
    if (macro.macro_id) {
      // open macro email
      return this.getMessageFromMacro();
    }
    // open reply to an email
    const draftProps = macro.isDraft
      ? { cc: macro.cc, bcc: macro.bcc, id: macro.id }
      : {};

    return {
      ...INIT_MESSAGE,
      subject: macro.subject,
      body_html: macro.body_template || this.createMessageBody(),
      recipients: macro.recipients || [],
      parent_id: macro.parent_id,
      ...draftProps
    };
  }

  renderDeleteButton = () => {
    const { loading, savedMessageDraftId } = this.state;
    const { messageDraft, macro } = this.props;

    if (!messageDraft?.id && !macro?.isDraft && !savedMessageDraftId)
      return null;

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          this.handleCancellation(closeConfirmation);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="messaging.drafts.delete.title"
        message="messaging.drafts.delete.message"
        button={
          <Button as="a" disabled={loading} color="red" basic icon="trash" />
        }
        buttons={buttons}
      />
    );
  };

  renderCancelButton = () => {
    const { loading, touched } = this.state;
    const cancelHandler = !touched ? { onClick: this.cancel } : {};

    const buttons = [
      {
        id: "cancel",
        label: "meta.actions.cancel",
        color: "grey",
        basic: true,
        onClick: close => close()
      },
      {
        id: "discard",
        label: "meta.actions.discard",
        color: "red",
        onClick: (close, event) => {
          close();
          this.cancel(event);
        }
      },
      {
        id: "save",
        label: "meta.actions.save",
        color: "primary",
        onClick: (close, event) => {
          this.onSaveDraft(event);
          close();
          this.cancel(event);
        }
      }
    ];

    return (
      <ConfirmationDialog
        title="messaging.drafts.cancel.title"
        message="messaging.drafts.cancel.message"
        button={
          <Button
            as="a"
            fluid
            basic
            className="right floated"
            disabled={loading}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...cancelHandler}
          >
            <FormattedMessage
              id="meta.actions.cancel"
              defaultMessage="meta.actions.cancel"
            />
          </Button>
        }
        buttons={buttons}
      />
    );
  };

  renderTemplate(tmpl, ctx) {
    return Object.keys(ctx).reduce((text, key) => {
      // @todo pls add comoment to explain what is replaced here:
      const re = new RegExp(`{{\\s*${key}\\s*}}`, "g");
      return text.replace(re, ctx[key]);
    }, tmpl);
  }

  render() {
    const form = this.buildForm();
    const title = this.getTitle();
    const {
      isLoading,
      message,
      cc,
      bcc,
      touched,
      showContactModal,
      contactModalType
    } = this.state;

    const { macro, activity, emailReplyIncludeThread } = this.props;

    let selectedRecipients = [];
    if (Array.isArray(form.fields.recipients.props.value)) {
      selectedRecipients = form.fields.recipients.props.value.map(v =>
        parseInt(v.replace("Contractor-", ""), 10)
      );
    }

    const tempAvailableContacts = this.getAvailableContactOptions();
    if (
      differenceWith(
        tempAvailableContacts,
        form.fields.recipients.props.options,
        isEqual
      )?.length > 0 ||
      differenceWith(
        form.fields.recipients.props.options,
        tempAvailableContacts,
        isEqual
      )?.length > 0
    ) {
      form.fields.recipients.props.options = tempAvailableContacts;
    }

    return (
      <Form
        loading={isLoading}
        id="message"
        onSubmit={form.handleSubmit(this.handleUpdate)}
      >
        <CreateContactDialog
          contactModalType={contactModalType}
          isOpened={showContactModal}
          onDialogClose={this.onDialogClose}
          onContactCreated={(contact, type) => {
            const newRecipient = `Contact-${contact.id}`;
            const newRecipientOption = {
              key: newRecipient,
              value: newRecipient,
              text: `${contact.first_name} ${contact.last_name} (${contact.email})`,
              disabled: false
            };
            this.setState(prevState => ({
              touched: true,
              recipientsOptions: [
                ...prevState.recipientsOptions,
                newRecipientOption
              ],
              message: {
                ...prevState.message,
                [type]: [...prevState.message[type], newRecipient]
              }
            }));
          }}
          activity={{
            activityId: activity?.id,
            projectId: activity?.project_id
          }}
        />
        <Field component="Input" {...form.fields.parent_id} type="hidden" />
        {title && <Header>{title}</Header>}
        <Form.Field>
          <FeatureToggle featureToggleName="show_cc_bcc" disabled>
            <Field component="Select" {...form.fields.recipients} />
          </FeatureToggle>

          <FeatureToggle featureToggleName="show_cc_bcc">
            <InputWithActions
              component="Select"
              field={{ ...form.fields.recipients }}
              actions={[
                {
                  label: "cc",
                  name: "cc",
                  color: cc ? "blue" : undefined,
                  handler: name =>
                    this.setState(prev => ({ ...prev, [name]: !prev[name] }))
                },
                {
                  label: "bcc",
                  name: "bcc",
                  color: bcc ? "blue" : undefined,
                  handler: name =>
                    this.setState(prev => ({ ...prev, [name]: !prev[name] }))
                }
              ]}
            />
          </FeatureToggle>
        </Form.Field>

        <If condition={cc}>
          <div
            style={{
              width: "100%",
              margin: "0 0 1rem 0"
            }}
          >
            <FeatureToggle featureToggleName="email_contacts" disabled>
              <Form.Field>
                <Field component="Select" {...form.fields.cc} />
              </Form.Field>
            </FeatureToggle>
            <FeatureToggle featureToggleName="email_contacts">
              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.cc}
                  props={{
                    ...form.fields.cc.props,
                    allowAdditions: false,
                    noResultsMessage: this.openContactDialogButton("cc")
                  }}
                />
              </Form.Field>
            </FeatureToggle>
          </div>
        </If>

        <If condition={bcc}>
          <div
            style={{
              width: "100%",
              margin: "0 0 1rem 0"
            }}
          >
            <FeatureToggle featureToggleName="email_contacts" disabled>
              <Form.Field>
                <Field component="Select" {...form.fields.bcc} />
              </Form.Field>
            </FeatureToggle>
            <FeatureToggle featureToggleName="email_contacts">
              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.bcc}
                  props={{
                    ...form.fields.bcc.props,
                    allowAdditions: false,
                    noResultsMessage: this.openContactDialogButton("bcc")
                  }}
                />
              </Form.Field>
            </FeatureToggle>
          </div>
        </If>

        <Form.Field>
          <Field component="Input" {...form.fields.subject} />
        </Form.Field>
        <Form.Field>
          <label htmlFor="bodyHtml">
            <FormattedMessage
              id="macro.attributes.body.label"
              defaultMessage="Nachricht"
            />
          </label>
          <WysiwygEditor
            value={message.body_html}
            isReply={macro.isReply}
            emailReplyIncludeThread={emailReplyIncludeThread}
            id="bodyHtml"
            name="bodyHtml"
            buttonsHide={["html"]}
            onChange={({ value: newBody }) => {
              this.setState(prevState => ({
                touched: true,
                message: {
                  ...prevState.message,
                  body_html: newBody
                }
              }));
            }}
          />
        </Form.Field>
        <If condition={!!message.parent_id && !emailReplyIncludeThread}>
          <Form.Field>
            <Field
              component="Checkbox"
              {...form.fields.render_message_thread}
            />
          </Form.Field>
        </If>
        <Form.Group>
          <Form.Field>
            <Field component={AttachmentsDialog} {...form.fields.attachments} />
          </Form.Field>
          <Form.Field>
            <If condition={macro.macro_id === "contractor_portal_enquiry"}>
              <If condition={selectedRecipients.length === 1}>
                <GeneratePreviewButton
                  contractorIds={selectedRecipients}
                  projectId={activity.project.id}
                  activityId={activity.id}
                />
              </If>
              <If condition={selectedRecipients.length > 1}>
                <GenerateMultiplePreviewButton
                  contractorIds={selectedRecipients}
                  projectId={activity.project.id}
                  activityId={activity.id}
                />
              </If>
            </If>
          </Form.Field>
        </Form.Group>
        <Form.Group className="actions" widths="equal">
          <Form.Field>
            {this.renderDeleteButton()}
            <If condition={touched}>
              <FeatureToggle featureToggleName="show_drafts">
                <Button
                  onClick={this.onSaveDraft}
                  disabled={isLoading}
                  basic
                  style={{ marginLeft: "5px" }}
                  icon="save"
                />
              </FeatureToggle>
            </If>
          </Form.Field>
          <Form.Field>
            <FeatureToggle featureToggleName="show_drafts">
              {this.renderCancelButton()}
            </FeatureToggle>
            <FeatureToggle featureToggleName="show_drafts" disabled>
              <Button
                as="a"
                fluid
                basic
                className="right floated"
                disabled={isLoading}
                onClick={this.cancel}
              >
                <FormattedMessage
                  id="meta.actions.cancel"
                  defaultMessage="meta.actions.cancel"
                />
              </Button>
            </FeatureToggle>
          </Form.Field>
          <Form.Field>
            <Button fluid type="submit" color="green" loading={isLoading}>
              <span>
                <FormattedMessage
                  id="meta.actions.send"
                  defaultMessage="meta.actions.send"
                />
              </span>
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}
MessageForm.defaultProps = {
  i18n: {},
  buyer: {},
  buyers: [],
  contractors: [],
  contacts: [],
  attachments: [],
  defaultAttachments: [],
  emailReplyIncludeThread: false,
  messageDraft: {},
  message: {}
};

MessageForm.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  macro: MacroShape.isRequired,
  i18n: I18nShape,
  activity: ActivityShape.isRequired,
  buyer: BuyerShape,
  buyers: PropTypes.arrayOf(BuyerShape),
  contractors: PropTypes.arrayOf(ContractorShape),
  // eslint-disable-next-line react/forbid-prop-types
  contacts: PropTypes.object,
  attachments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultAttachments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  ctxCtrl: PropTypes.instanceOf(ActivityController).isRequired,
  emailReplyIncludeThread: PropTypes.bool,
  saveDraft: PropTypes.func.isRequired,
  deleteDraft: PropTypes.func.isRequired,
  loadActivityDrafts: PropTypes.func.isRequired,
  messageDraft: MessageShape,
  message: MessageShape,
  growl: PropTypes.instanceOf(Growl).isRequired
};

const mapDispatchToProps = (dispatch, props) => {
  const messageDraftResource = new MessageDraftsResource(dispatch);

  const activityId = props.activity?.id;
  const messageDraftActivityResource = new MessageDraftsForActivitiesResource(
    dispatch,
    activityId
  );

  return {
    loadActivityDrafts: () => {
      return messageDraftActivityResource.fetchAll();
    },
    saveDraft: params => {
      return messageDraftResource.save(params);
    },
    deleteDraft: id => {
      return messageDraftResource.remove(id);
    },
    growl: new Growl(dispatch)
  };
};

export default connect(null, mapDispatchToProps)(MessageForm);
