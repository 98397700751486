import React from "react";
import { Grid, Form, Message } from "semantic-ui-react";

class AddSalesAccount extends React.Component {
  state = {
    account_name: "",
    account_domain: "",
    project_name: "",
    error: null,
    success: null,
    loading: false
  };

  handleSubmit = () => {
    const { account_name, account_domain, project_name } = this.state;
    this.setState({
      account_name,
      account_domain,
      project_name,
      loading: true
    });

    fetch("/api/v1/support_utils/add_sales_account", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        account: {
          account_name,
          account_domain,
          project_name
        }
      })
    })
      .then(response => response.json())
      .catch(error =>
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        })
      )
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      });
  };

  handleChange = (e, { name, value, checked }) => {
    this.setState({ [name]: checked !== undefined ? checked : value });
  };

  render() {
    const { i18n } = this.props;
    const {
      account_name,
      account_domain,
      project_name,
      error,
      success,
      loading
    } = this.state;

    return (
      <div>
        <h2>Add Account For a Sales Pitch</h2>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width="3">
            <Form
              loading={loading}
              success={success}
              error={!!error}
              onSubmit={this.handleSubmit}
            >
              <Message
                success
                header="Account Added!"
                content="Please check the results!"
              />
              <Message error header="Account Creation Failed" content={error} />
              <Form.Input
                name="account_name"
                value={account_name}
                onChange={this.handleChange}
                label="Account Name"
                placeholder="Bau Digital GmbH"
                required
                width="4"
              />
              <Form.Input
                name="account_domain"
                value={account_domain}
                onChange={this.handleChange}
                label="Account Domain"
                placeholder="baudigital.com"
                required
                width="4"
              />
              <Form.Input
                name="project_name"
                value={project_name}
                onChange={this.handleChange}
                label="Project Name"
                placeholder="Metropolapart"
                required
                width="4"
              />
              <Form.Button width="4">Submit</Form.Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}

export default AddSalesAccount;
